import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import DownloadDropdown from './DownloadDropdown'
import css from './SecondaryNav.module.scss'
import UCookies from 'universal-cookie'
import dynamic from 'next/dynamic'
import { useAuctionProvider } from '@/lib/AuctionProvider'
const IconoGraphy = dynamic(()=>import('../IconoGraphy')) 

const SecondaryNav = ({
  stickyHeader,
  setRevampNavOpen,
  showStickyMobileHeader
}) => {
  // Dropdown states
  const [showDropdown, setShowDropdown] = useState(false)
  const [showProfileIcon, setShowProfileIcon] = useState(false)
  const { t } = useTranslation('translations-auction')
  const {isEditPopUpOpen = false} = useAuctionProvider() || {}
  let fillColor = 'color_grey_dim'
  const cookies = new UCookies()
  const authToken = cookies.get('authToken')
  const authUserId = cookies.get('userId')

  useEffect(() => {
    setShowProfileIcon(true)
  }, [])

  const profileclick = (e) => {
    e.stopPropagation()
    setRevampNavOpen(true)
  }

  const isDropDownavailable = () => showDropdown && !isEditPopUpOpen
  
  return (
    <div className={`${css.navbar} ${showStickyMobileHeader ? css.isNotDesktop: ''}`}>
      <div className={css.navbar__item}>
        <div className={css.actionBtns}>
          <button
            className={css.downloadAppBtn}
            onClick={(e) => {
              e.stopPropagation()
              setShowDropdown(!showDropdown)
            }}
          > 
            <IconoGraphy iconClass={'icon-app-download'} iconColor={fillColor}></IconoGraphy>
            {t('globalNavigation.DOWNLOAD_APP')}
          </button>
          {isDropDownavailable() && (
            <DownloadDropdown
              onClose={() => {
                setShowDropdown(!showDropdown)
              }}
            />
          )}
        </div>
        <div
          onClick={profileclick}
        >
          <div className={`${css.profile} ${!stickyHeader ? css.profileHoverFixonDarkBG : ''}`}>
            <IconoGraphy iconClass={'icon-menu-more'} iconColor={fillColor}></IconoGraphy>
            <IconoGraphy
              iconClass={authToken && authUserId && showProfileIcon ? 'icon-loggedin-profile' : 'icon-profile'}
              iconColor={fillColor}
            ></IconoGraphy>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondaryNav
