import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import styles from './DownloadDropdown.module.scss'
import useTranslation from 'next-translate/useTranslation'
import { useClickOutsideListenerRef } from '@/utils/useClickOutsideListener'

const DownloadDropdown = (props) => {
  const { t } = useTranslation('translations-auction')
  const { onClose } = props;
  const handlePopupClose = () => {
    onClose()
  }
  const refPopup = useClickOutsideListenerRef(handlePopupClose)

  return (
    <div className={styles.container} ref={refPopup}>
      <div className={styles.box}>
        <div className={styles.SecOne}>
          <p>{t('Home.SCAN_QR')}</p>
        </div>
        <div className={styles.SecTwo}>
          <Image src='/images/Wasalt_QR-code_HighRes.svg' layout='fill' />
        </div>
        <div className={styles.SecThree}>
          <Link href='https://apps.apple.com/in/app/wasalt/id1588885673' prefetch={false}>
            <Image src='/images/AppStore.svg' layout='fill' />
          </Link>
        </div>
        <div className={styles.SecFour}>
          <Link href='https://play.google.com/store/apps/details?id=com.wasalt.app' prefetch={false}>
            <Image src='/images/PlayStore.svg' layout='fill' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default DownloadDropdown
